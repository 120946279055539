import './App.css';
import './fonts.css';
import Clock from './components/Clock.js';
import { Link, Element } from 'react-scroll';

function App() {
  return (
    <>
      <div class="div-with-pattern">
        <Element name = 'homesection' class = 'home'>
          <section class = 'introsection'>
            <p class = "intro">Hey! I'm Jeffrey</p>
            <p class = 'intro-text'>A 2nd year student studying computer science at TMU (formerly Ryerson).</p>
            <p class = 'and'>&</p>
            <p class = 'youandme'>I present to YOU, everything about ME.</p>
          </section>
          <section class = 'clockportraitcontainer'>
            <div class = 'clock'><Clock timeZone="US/Eastern"/></div>
            <div class = 'portrait'></div>
          </section>
          <ul class = 'navigationbuttons'>
            <li><Link to = 'homesection' smooth = 'true' duration = {500}><button class = 'basic-button'>Home</button></Link></li>
            <li><Link to = 'projectsection' smooth = 'true' duration = {500}><button class = 'basic-button'>Projects</button></Link></li>  
            <li><Link to = 'aboutmesection' smooth = 'true' duration = {500}><button class = 'basic-button'>About me</button></Link></li> 
          </ul>
        </Element>
        <div class = 'homedivider'></div>
        <Element name = 'projectsection' class = 'projectcontainer'>
          <div class = 'topprojectcontainer'>
            <button>Wordle</button>
            <header>
              <div>Wordle</div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur quis arcu vitae diam dignissim efficitur. Donec nec congue lacus. Integer tempus lorem quis risus varius, non lobortis eros consectetur. Aliquam fringilla est condimentum, imperdiet urna sed, finibus tortor.</p>
            </header>
            <button>Flutter Quiz App</button>
            <header>
              <div>Quiz App</div>
              <p>Hello</p>
            </header>
          </div>
          <div class = 'bottomprojectcontainer'>
            <button>TMUber</button>
            <header>
              <div>TMUber</div>
              <p>Hello</p>
            </header>
            <button>Personal Website</button>
            <header>
              <div>Personal Website</div>
              <p>Hello</p>
            </header>
          </div>
        </Element>
        <div class = 'projectdivider'></div>
        <Element name = 'aboutmesection' class = 'aboutmecontainer'>

        </Element>
        <div class = 'regard'></div>
      </div>
    </>
  );
}

export default App;
